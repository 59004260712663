import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

const CTA_CLICKED = 'cta_clicked';
const CTA_CLICKED_JOURNEY = 'cta_clicked_journey';
const TRUTHY_VALUE = 'true';

const isCtaJourney = () =>
  localStorage.getItem(CTA_CLICKED_JOURNEY) === TRUTHY_VALUE;
const setStorage = () =>
  localStorage.setItem(CTA_CLICKED_JOURNEY, TRUTHY_VALUE);
const clearStorage = () => localStorage.removeItem(CTA_CLICKED_JOURNEY);

const useHandleCTAConversionParam = () => {
  const { search } = useLocation();

  const checkQueryParams = useCallback(() => {
    const ctaClickedParam = new URLSearchParams(search).get(CTA_CLICKED);

    if (ctaClickedParam === TRUTHY_VALUE) {
      setStorage();
    }
  }, [search]);

  useEffect(() => {
    checkQueryParams();
  }, [checkQueryParams]);

  return {
    clearCTAConversionFlag: clearStorage,
    isCTAConversionJourney: isCtaJourney(),
  };
};

export { useHandleCTAConversionParam };
